import { Box, Typography } from '@mui/material';
import React from 'react';

const RenderLabels = (labels) => (
  <Box>
    {labels?.map((label) => (
      <Typography key={label}>{label}</Typography>
    ))}
  </Box>
);

export default RenderLabels;
