const replace = {
  changeSMID: 'Wijzig de Febesol ID',
  reqSMID: 'Febesol ID *',
  SMID: 'Febesol ID',
  SMIDTolltip: 'De ID is een unieke identificatie van uw apparaat. Deze ID vind je op het toestel onder de QR-code.',
  enableSmId: 'Febesol ID',
  smidLabel: 'Febesol ID',
  confirmDeletingDesc: 'Bevestig het verwijderen van alle gegevens van deze gateway door de Febesol ID in te voeren. Eenmaal bevestigd, kan dit proces niet meer worden teruggedraaid.',
  confirmDeletingError: 'De ID komt niet overeen met de Febesol ID. De gateway wordt niet verwijderd.'
};

export default replace;
