import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'react-router-redux';
import moment from 'moment-timezone';
import Highcharts from 'highcharts';
import addNoDataModule from 'highcharts/modules/no-data-to-display';

import { createSelector } from 'reselect';
import {
  LoadManagementDashboardContainer,
  LoadManagementCurrentValues,
  LoadManagementChartControls
} from './components';
import CalendarPickerModal from '../DashboardV2/components/CalendarPickerModal';
import { TopBar } from '../../components';
import formatInterval from '../../utils/formatInterval';
import { closeModalWindow, DATE_PICKER_MODAL_MODAL_ID, openModalWindow } from '../ModalWindow/slice';
import { chartChangeRange, generateRequestQuery } from './utils';
import './index.scss';
import { useGetUserFromQueryQuery } from '../../services/userService';
import { useGetLoadManagementDataQuery, useLazyGetRealtimeUpdateQuery } from '../../services/loadManagementService';
import { fetchChartDataSuccess } from '../../services/loadManagementService/slice';
import { getSubTypeRange } from '../DashboardV2/chartUtils';
import { getMaxDetail } from '../../utils';

addNoDataModule(Highcharts);

const chartDataSelector = createSelector((state) => state.loadManagementServiceSlice, (chartData) => chartData);

const LoadManagementDashboard = (props) => {
  const { myUserID, location, match } = props;
  const dispatch = useDispatch();

  const [realTimeCounter, setRealTimeCounter] = useState(0);

  const { userId } = match.params;
  const myself = !(userId && userId !== myUserID);
  const { data: user } = useGetUserFromQueryQuery({ userID: match.params.userId || myUserID, mySelf: !(userId && userId !== myUserID) });

  const chartData = useSelector(chartDataSelector);

  const searchParams = new URLSearchParams(window.location.search);
  const scaleTypeFromURL = searchParams.get('type');

  const fromFromURL = searchParams.get('from');
  const toFromURL = searchParams.get('to');
  const subTypeFromURL = searchParams.get('subType');
  const subRange = useMemo(() => getSubTypeRange(chartData), [chartData]);

  const requestQueries = useMemo(() => {
    moment.tz.setDefault(user?.gateway?.timeZone || 'UTC');
    return generateRequestQuery(fromFromURL, toFromURL, scaleTypeFromURL);
  }, [fromFromURL, scaleTypeFromURL, toFromURL, user?.gateway?.timeZone]);

  const userName = user ? `${user.first_name} ${user.last_name}` : '';
  const { _id: gatewayId } = user?.gateway || {};

  const { data: transformedResponse, isLoading, isFetching } = useGetLoadManagementDataQuery(
    { gatewayId, ...requestQueries },
    { skip: !gatewayId }
  );

  const [fetchRealtimeUpdate] = useLazyGetRealtimeUpdateQuery();

  const closeDatePicker = () => dispatch(closeModalWindow({ modalID: DATE_PICKER_MODAL_MODAL_ID }));

  const openDatePicker = () => dispatch(openModalWindow({ modalID: DATE_PICKER_MODAL_MODAL_ID, data: null }));

  const replaceFromProps = (path) => dispatch(replace(path));

  const handleChangeCalendar = (date) => {
    closeDatePicker();
    const dateType = (scaleTypeFromURL ?? subRange) === 'w' ? 'week' : 'day';
    const from = moment(date).startOf(dateType);
    const to = moment(date).endOf(dateType);
    chartChangeRange(from, to, replaceFromProps, location.pathname, dateType[0]);
  };

  const zoomHandler = (xScale) => {
    if (xScale.max && xScale.min && moment(xScale.max).diff(moment(xScale.min), 'minutes') < 5) {
      return false;
    }

    chartChangeRange(xScale.min, xScale.max, replaceFromProps, location.pathname);
    return true;
  };

  useEffect(() => {
    if (transformedResponse) {
      dispatch(fetchChartDataSuccess({
        chartData: transformedResponse.data,
        type: transformedResponse.originalArg.type,
        from: transformedResponse.originalArg.from,
        to: transformedResponse.originalArg.to,
        scaleMs: transformedResponse.originalArg.scaleMs
      }));
    }
  }, [transformedResponse, dispatch]);

  useEffect(() => {
    let interval;
    if (gatewayId) {
      interval = setInterval(() => {
        setRealTimeCounter((prev) => prev + 1);
      }, 10000);
    }

    return () => clearInterval(interval);
  }, [gatewayId]);

  useEffect(() => {
    if (chartData?.from && realTimeCounter) {
      const lastTo = moment(chartData.realTimeData.to || chartData.to);

      fetchRealtimeUpdate({
        gatewayId,
        onlySmartMeter: !(moment().diff(lastTo, 'minutes') < 5 && moment().diff(lastTo, 'seconds') >= 10)
          || isFetching
          || isLoading,
        type: requestQueries.type,
        from: chartData.realTimeData.to || chartData.to,
        scaleMs: requestQueries.scaleMs,
        interval: chartData.interval
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realTimeCounter]);

  return (
    <>
      <CalendarPickerModal
        maxDetail={getMaxDetail(scaleTypeFromURL ?? subRange)}
        from={moment(chartData.chartData?.from || chartData.from).valueOf()}
        handleChange={handleChangeCalendar}
      />

      <TopBar
        visibleWeb
        goBackButton={!myself}
        pageInfo={!myself ? userName : false}
      />
      <LoadManagementCurrentValues
        houseFuse={user?.houseSettings?.houseFuse || 0}
        smartMeterData={chartData.smartMeter}
      />
      <LoadManagementChartControls
        replaceFromProps={replaceFromProps}
        scaleType={chartData.type || scaleTypeFromURL || 't'}
        subType={subTypeFromURL}
        location={location}
        openDatePicker={openDatePicker}
        intervalStr={formatInterval(chartData.realTimeData?.from || chartData.from, chartData.realTimeData?.to || chartData.to)}
        zoom
        chart={chartData}
      />
      {user ? (
        <LoadManagementDashboardContainer
          zoomHandler={zoomHandler}
          chartData={chartData}
          houseFuse={user?.houseSettings?.houseFuse || 0}
          loading={isLoading || isFetching}
          user={user}
        />
      ) : null}
    </>
  );
};

LoadManagementDashboard.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  myUserID: PropTypes.string.isRequired
};

export default LoadManagementDashboard;
