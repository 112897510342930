/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import compose from 'lodash/fp/compose';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { pick } from 'lodash';
import { USER_HOUSE_SETTINGS_URL } from '../../../api/apiUrls';
import { GENERAL_SETTINGS_MODAL_ID, modalWindowSend } from '../../ModalWindow/slice';
import EditGeneralSettingsWindow from '../components/EditGeneralSettingsWindow';
import UserInfo from './components/UserInfo';
import { TabPanel, GeneralInfo, Software, SupportTab, LocalApi } from './components';
import { settingPrGetUser } from '../../SettingProfile/slice';
import { getUserInfo } from '../slice';
import { getPagesSettingsSelector } from '../../../redux-store/selectors/pagesSettings';
import { setPagesSetting } from '../../PagesSettings/slice';
import i18n from '../../../i18n';
import { APP_ID } from '../../../config';
import { CommonInfoModalWindow, ChooseLicenseWindow } from '../../../modals';
import { isInvitedUserHasReadOnlyPermissionSelector, isPvEmployeeInstallHasReadOnlyAccessSelector } from '../../../redux-store/selectors/userDevices';

import './index.scss';
import { signInUpdateUser } from '../../SignIn/slice';

const transformToNumber = (name) => (fields) => ({ ...fields, [name]: Number(fields?.[name]) });

const transformFieldsForAPI = (formFields) => {
  const composer = compose(...['offsetWatt', 'kWp', 'houseFuse'].map(transformToNumber));

  if (Number(formFields?.houseFuse) > 0) {
    return composer({ ...formFields });
  }

  return composer({ ...formFields, loadManagement: false });
};

/**
* Block with user, software and general info. Viewed by everyone except end_user.
*/
const UserDetailsTabs = (props) => {
  const { user = {}, myRoleType } = props;
  const dispatch = useDispatch();
  const { userDetails: userDetailsSettings = {} } = useSelector(getPagesSettingsSelector);
  const isReadOnlyPermission = useSelector(isInvitedUserHasReadOnlyPermissionSelector);
  const isPvEmployeeInstallHasReadOnlyAccess = useSelector(isPvEmployeeInstallHasReadOnlyAccessSelector);

  const updateSettings = () => (myRoleType === 'end_user'
    ? settingPrGetUser({ userID: undefined, myself: true, roleLink: 'solar-managers-users', successfulCB: (result) => dispatch(signInUpdateUser({ user: result })) })
    : getUserInfo({ userId: user._id }));

  const updateHouseSettings = (fields) => dispatch(
    modalWindowSend({
      modalID: GENERAL_SETTINGS_MODAL_ID,
      requestConfig: {
        method: 'put',
        url: `${USER_HOUSE_SETTINGS_URL}${user._id ? `/${user._id}` : ''}`,
        data: transformFieldsForAPI(fields)
      },
      cb: updateSettings
    })
  );

  const handleTabChange = (_, value) => dispatch(setPagesSetting({ page: 'userDetails', data: { userInfoActiveTab: value } }));

  const tabProps = {
    user,
    myRoleType,
    isReadOnlyPermission: isReadOnlyPermission || isPvEmployeeInstallHasReadOnlyAccess || myRoleType === 'pv_installer_employee_read_only'
  };

  return (
    <>
      {
        user?.license?.type === 'initial'
        && user?.license?.editableForAppIdentifiers?.includes(APP_ID)
        && ['solar_admin', 'root', 'pv_installer', 'oem', 'pv_installer_employee'].includes(myRoleType)
        && (
          <ChooseLicenseWindow callback={updateSettings} type="initial" />
        )
      }
      <CommonInfoModalWindow
        modalID="requireMoreTimeID"
        isShowEachTime
        label="requireMoreTimeLabel"
        info="requireMoreTimeInfo"
      />
      <EditGeneralSettingsWindow
        onSubmit={updateHouseSettings}
        initialValues={pick(user?.houseSettings, ['offsetWatt', 'kWp', 'houseFuse', 'loadManagement'])}
        enableReinitialize
      />
      <div className="user-settings">
        <Tabs
          value={userDetailsSettings.userInfoActiveTab}
          onChange={handleTabChange}
          textColor="inherit"
          sx={{ px: 2 }}
        >
          <Tab disableRipple value={0} label={i18n.t('tabEndUserInfo')} />
          <Tab disableRipple value={1} label={i18n.t('generalSettings')} />
          <Tab disableRipple value={2} label={i18n.t('software')} />
          <Tab disableRipple value={3} label={i18n.t('localApi')} />
          {['root', 'solar_admin'].includes(myRoleType) && <Tab disableRipple value={4} label={i18n.t('support')} />}

        </Tabs>

        <TabPanel classes="main-info-tab" value={userDetailsSettings.userInfoActiveTab} index={0}>
          <UserInfo label={i18n.t('tabEndUserInfo')} {...tabProps} />
        </TabPanel>
        <TabPanel value={userDetailsSettings.userInfoActiveTab} index={1}>
          <GeneralInfo label={i18n.t('generalSettings')} {...tabProps} />
        </TabPanel>
        <TabPanel value={userDetailsSettings.userInfoActiveTab} index={2}>
          <Software label={i18n.t('software')} {...tabProps} />
        </TabPanel>
        <TabPanel value={userDetailsSettings.userInfoActiveTab} index={3}>
          <LocalApi label={i18n.t('localApi')} {...tabProps} />
        </TabPanel>
        {['root', 'solar_admin'].includes(myRoleType) && (
          <TabPanel value={userDetailsSettings.userInfoActiveTab} index={4}>
            <SupportTab label={i18n.t('support')} {...tabProps} />
          </TabPanel>
        )}
      </div>
    </>
  );
};

UserDetailsTabs.propTypes = {
  user: PropTypes.instanceOf(Object),
  myRoleType: PropTypes.string
};

export default UserDetailsTabs;
