import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { openModalWindow, UPGRADE_TO_UNLOCK_POPUP } from '../../../containers/ModalWindow/slice';
import { addNotification } from '../../../containers/NotificationGenerator/slice';
import i18n from '../../../i18n';

const CustomSelectOption = (props) => {
  const dispatch = useDispatch();
  const { innerProps, isDisabled, data } = props;

  const openUpgradePopup = () => {
    if (data.licenseType === 'initial') {
      dispatch(addNotification({ type: 'error', text: i18n.t('forbidden') }));
    } else {
      dispatch(openModalWindow({ modalID: UPGRADE_TO_UNLOCK_POPUP }));
    }
  };

  return (
    <div
      {...innerProps}
      className="react-select__option"
      style={{ opacity: isDisabled ? 0.55 : 1 }}
      onClick={data.onClick || (data.isOpenUpgradePopup ? openUpgradePopup : innerProps.onClick)}
    >
      {data.label}
    </div>
  );
};

CustomSelectOption.propTypes = {
  innerProps: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  isDisabled: PropTypes.bool
};

export default CustomSelectOption;
