import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addNotification } from '../containers/NotificationGenerator/slice';

export const useOauthFlowCallback = (search, _history, callback) => {
  const dispatch = useDispatch();
  const state = new URLSearchParams(search).get('state');
  const errorDescription = new URLSearchParams(search).get('errorDescription');

  useEffect(() => {
    if (state) {
      const stateObj = state?.split(',').reduce((acc, item) => {
        const [key, value] = item.split(':');
        acc[key] = value;
        return acc;
      }, {}) || {};

      const { stateId, ...rest } = stateObj;

      if (stateId) {
        const stateData = JSON.parse(sessionStorage.getItem(stateId));

        if (stateData && !errorDescription) {
          callback(stateData, rest);
        }

        sessionStorage.removeItem(stateId);
      }
      window.history.replaceState({}, document.title, window.location.href.split('?')[0]);
    }
    if (errorDescription) {
      dispatch(
        addNotification({
          type: 'error',
          text: errorDescription
        })
      );
      window.history.replaceState({}, document.title, window.location.href.split('?')[0]);
    }
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};
