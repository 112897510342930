import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../../../api_helper';

import { faultTypes, categories } from '../constants';

export const productionAnalysisApi = createApi({
  reducerPath: 'productionAnalysisApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['overview', 'pvChart'],
  endpoints: (builder) => ({
    getOverview: builder.query({
      query: ({
        from,
        to,
        type
      }) => {
        const params = type === 'overall' ? { view: type } : { from, to, view: type };

        return ({
          url: '/production-analysis/overview',
          method: 'get',
          params
        });
      },
      providesTags: ['overview']
    }),
    getPvChart: builder.query({
      query: ({
        from,
        to,
        type,
        gatewayId,
        stringsId
      }) => {
        const params = type === 'overall' ? { view: type, stringsId } : { from, to, view: type, stringsId };

        return ({
          url: `/production-analysis/pv-chart/${gatewayId}`,
          method: 'get',
          params
        });
      },
      providesTags: ['pvChart']
    }),
    getHeatmap: builder.query({
      query: ({
        from,
        to,
        type,
        gatewayId,
        stringsId
      }) => ({
        url: `/production-analysis/heatmap/${gatewayId}`,
        method: 'get',
        params: { from, to, view: type, stringsId }
      }),
      providesTags: ['heatmap'],
      transformResponse: (data) => {
        const { chart } = data;

        const updatedChart = chart.filter(({ value }) => value !== null).map((item) => {
          item.custom = { type: faultTypes[item.value] };
          item.y = categories.indexOf(item.y);

          if (item.relativeLoos) {
            item.custom.relativeLoos = Number((item.relativeLoos / 100).toFixed(2));
            item.value = Number((item.value + (item.relativeLoos / 100)).toFixed(2));
          }

          return item;
        });

        data.chart = updatedChart;

        return data;
      }
    }),
    getZips: builder.query({
      query: () => ({
        url: '/users/zips',
        method: 'get'
      }),
      transformResponse: (data) => data.map((zip) => ({ label: zip, value: zip }))
    }),
    getLabels: builder.query({
      query: () => ({
        url: '/gateway/labels',
        method: 'get'
      }),
      transformResponse: (data) => data.map((label) => ({ label, value: label })),
      providesTags: ['labels']
    }),
    createLabel: builder.mutation({
      query: (data) => ({
        url: '/gateway/labels',
        method: 'post',
        data
      }),
      invalidatesTags: ['labels']
    }),
    removeLabel: builder.mutation({
      query: ({ gatewayId }) => ({
        url: `/gateway/labels/${gatewayId}`,
        method: 'delete'
      }),
      invalidatesTags: ['labels']
    }),
    getCompareSystems: builder.query({
      refetchOnMountOrArgChange: true,
      keepUnusedDataFor: 0,
      query: ({ from, to, view, gatewaysId }) => {
        const params = new URLSearchParams({ gatewaysId });

        if (from) params.append('from', from);
        if (to) params.append('to', to);
        params.append('view', view || 'month');

        return ({
          url: `/production-analysis/compare?${params.toString()}`,
          method: 'get'
        });
      },
      transformResponse: (data) => data,
      providesTags: []
    }),
    getDailyProduction: builder.query({
      query: ({ from, to, gatewayId }) => ({
        url: `/production-analysis/daily-production/${gatewayId}`,
        method: 'get',
        params: { from, to }
      }),
      transformResponse: (data = {}) => {
        const { chart } = data;

        const simulatedYeld = [];
        const pvYeld = [];
        const range = [];

        chart?.forEach((item) => {
          simulatedYeld.push([item.date, item.simulatedYieldW]);
          pvYeld.push([item.date, item.pvYieldW]);
          range.push([item.date, item.minW, item.maxW]);
        });

        return {
          simulatedYeld,
          pvYeld,
          range,
          from: data.from,
          to: data.to
        };
      }
    }),
    getSettings: builder.query({
      query: () => ({
        url: '/production-analysis/settings',
        method: 'get'
      }),
      providesTags: ['settings']
    }),
    updateSettings: builder.mutation({
      query: ({ data }) => ({
        url: '/production-analysis/settings',
        method: 'post',
        data
      }),
      invalidatesTags: ['settings']
    })
  })
});

export const {
  useGetOverviewQuery,
  useGetPvChartQuery,
  useGetHeatmapQuery,
  useGetZipsQuery,
  useGetLabelsQuery,
  useCreateLabelMutation,
  useRemoveLabelMutation,
  useGetCompareSystemsQuery,
  useLazyGetDailyProductionQuery,
  useGetSettingsQuery,
  useUpdateSettingsMutation
} = productionAnalysisApi;
