import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { TARIFF_VARIABLE_STEP_COLORS } from '../constants';

import i18n from '../../../i18n';

export const TariffPrice = ({ tariff, tariffPriceCurrency }) => {
  if (!tariff) {
    return null;
  }

  const { price, prices, tariffType } = tariff;

  if (prices) {
    const tariffPrices = Object.entries(prices).slice(0, tariff.stepsCount || 2).map(([key, value]) => {
      const color = TARIFF_VARIABLE_STEP_COLORS[key];
      return (
        <Box key={key} className="text-nowrap">
          <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8" fill="none">
            <circle cx="4.75" cy="4" r="4" fill={color} />
          </svg>
          <p className="new-design typography-caption text-base1 d-inline-block pl-4">
            {i18n.t(key)}
            {' - '}
            {`${value} ${tariffPriceCurrency}`}
          </p>
        </Box>
      );
    });

    return (
      <>
        <p className="new-design typography-body1 text-accent2 text-nowrap">{i18n.t('Price')}</p>
        <Box className="new-design typography-body1 text-base1 text-nowrap">
          <Box sx={{
            display: 'grid',
            gridTemplateColumns: {
              sm: 'auto',
              md: 'auto auto'
            },
            gapX: 1.5,
            gapY: 0.5
          }}
          >
            {tariffPrices}
          </Box>
        </Box>
      </>
    );
  }

  if (!price || tariffType === 'dynamic') {
    return null;
  }

  return (
    <>
      <p className="new-design typography-body1 text-accent2 text-nowrap">{i18n.t('Price')}</p>
      <Box className="new-design typography-body1 text-base1 text-nowrap">
        <p className="new-design typography-body1 text-base1">{`${price} ${tariffPriceCurrency || ''} `}</p>
      </Box>
    </>
  );
};

TariffPrice.propTypes = {
  tariff: PropTypes.instanceOf(Object),
  tariffPriceCurrency: PropTypes.string
};
