import { combineReducers } from '@reduxjs/toolkit';
import { routerReducer } from 'react-router-redux';
import { reducer as reduxReducer } from 'redux-form';

import users from './Users/slice';
import signIn from './SignIn/slice';
import signUp from './SignUp/slice';
import signUp2 from './SignUp2/slice';
import overview from './Overview/slice';
import payments from './Payments/slice';
import dataList from './DataList/slice';
import modals from './ModalWindow/slice';
import monitoring from './Monitoring/slice';
import userDevices from './UserDetails/slice';
import installedSM from './InstalledSM/slice';
import forgotPassword from './ForgotPass/slice';
import devicesTable from './DevicesTable/slice';
import sensorsOauth2 from './SensorsOauth2/slice';
import pagesSettings from './PagesSettings/slice';
import viewerDetails from './ViewersDetails/slice';
import cloudStatus from './CloudStatusWindow/slice';
import settingProfile from './SettingProfile/slice';
import documentation from './Documentation/store/slice';
import verifyChangeEmail from './VerifyChangeEmail/slice';
import productionAnalysis from './ProductionAnalysis/store/slice';
import notifications from './NotificationGenerator/slice';
import pvInstallersDetails from './PVInstallersDetails/slice';
import monitoringNotifications from './MonitoringNotification/slice';
import { feedInManagementApi } from './FeedInManagement/services';
import { dashboardApi } from './DashboardV2/services';
import { productionAnalysisApi } from './ProductionAnalysis/services';
import { flexDashboardApi } from './FlexDashboard/services';
import { userApi } from '../services/userService';
import { tariffsApi } from '../services/tariffService';
import userSlice from '../services/userService/slice';
import { dashboard as dashboardV2, currentValues as dashboardV2CurrentValues } from './DashboardV2/slice';
import heatPumpMonitoringSlice from './HeatPumpMonitoring/slice';
import { userDetailsApi } from './UserDetails/services';
import { paymentsApi } from '../services/payments';
import { InstalledSMApi } from './InstalledSM/services';
import { dataImportApi } from '../modals/DataImportModal/services';
import { DevicesTableApi } from './DevicesTable/services';
import { InvitedUsersApi } from './InvitedUsers/services';
import { loginApi } from './SignIn/services';
import { HeatPumpMonitoringApi } from './HeatPumpMonitoring/services';
import { FeatureFlagsApi } from '../services/featureFlags';
import { loadManagementApi } from '../services/loadManagementService';
import loadManagementServiceSlice from '../services/loadManagementService/slice';

export default combineReducers({
  form: reduxReducer,
  router: routerReducer,
  signIn,
  users,
  signUp,
  modals,
  signUp2,
  dataList,
  overview,
  payments,
  monitoring,
  userDevices,
  installedSM,
  cloudStatus,
  devicesTable,
  viewerDetails,
  sensorsOauth2,
  notifications,
  pagesSettings,
  documentation,
  forgotPassword,
  settingProfile,
  verifyChangeEmail,
  pvInstallersDetails,
  monitoringNotifications,
  dashboardV2,
  dashboardV2CurrentValues,
  heatPumpMonitoring: heatPumpMonitoringSlice,
  productionAnalysis,
  [feedInManagementApi.reducerPath]: feedInManagementApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [flexDashboardApi.reducerPath]: flexDashboardApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [tariffsApi.reducerPath]: tariffsApi.reducer,
  userSlice,
  [userDetailsApi.reducerPath]: userDetailsApi.reducer,
  [InstalledSMApi.reducerPath]: InstalledSMApi.reducer,
  [dataImportApi.reducerPath]: dataImportApi.reducer,
  [DevicesTableApi.reducerPath]: DevicesTableApi.reducer,
  [productionAnalysisApi.reducerPath]: productionAnalysisApi.reducer,
  [InvitedUsersApi.reducerPath]: InvitedUsersApi.reducer,
  [loginApi.reducerPath]: loginApi.reducer,
  [paymentsApi.reducerPath]: paymentsApi.reducer,
  [HeatPumpMonitoringApi.reducerPath]: HeatPumpMonitoringApi.reducer,
  [FeatureFlagsApi.reducerPath]: FeatureFlagsApi.reducer,
  [loadManagementApi.reducerPath]: loadManagementApi.reducer,
  loadManagementServiceSlice
});
