import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../../api_helper';
import { addNotification } from '../../containers/NotificationGenerator/slice';
import { PROVIDERS_URL, PROVIDER_OAUTH_URL } from '../../api/apiUrls';
import i18n from '../../i18n';

export const tariffsApi = createApi({
  reducerPath: 'tariffsApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ['tariff', 'providers'],
  endpoints: (builder) => ({
    getTariffs: builder.query({
      providesTags: ['tariff'],
      refetchOnMountOrArgChange: true,
      keepUnusedDataFor: 0,
      query: ({ userId, type, tariffId, fields }) => {
        const url = tariffId
          ? `/settings/tariffs/${userId}/${type}/${tariffId}`
          : `/settings/tariffs/${userId}/${type}`;

        if (fields) {
          const queryParams = new URLSearchParams();

          queryParams.append('fields', fields);

          return {
            url: `${url}?${queryParams.toString()}`,
            method: 'get'
          };
        }

        return ({ url, method: 'get' });
      },
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          dispatch(addNotification({ type: 'error', text: error.message }));
        }
      }
    }),
    getProviders: builder.query({
      providesTags: ['providers'],
      refetchOnMountOrArgChange: true,
      keepUnusedDataFor: 0,
      query: ({ userId, type }) => ({
        url: `${PROVIDERS_URL}?userId=${userId}&tariffType=${type}`,
        method: 'get',
        headers: { 'api-version': 3 }
      }),
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          dispatch(addNotification({ type: 'error', text: error.message }));
        }
      }
    }),
    startOauth2ForProviders: builder.query({
      refetchOnMountOrArgChange: true,
      query: (data) => {
        const query = Object.keys(data).map((key) => `${key}=${data[key]}`).join('&');
        return { url: `${PROVIDER_OAUTH_URL}?${query}`, method: 'get' };
      },

      async onQueryStarted(_args, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          window.location.href = data.authUrl;
        } catch (error) {
          console.log(error, 'error');
        }
      }
    }),

    updateOrCreateTariff: builder.mutation({
      invalidatesTags: (_, error) => (error ? [] : ['tariff']),
      query: ({ userId, type, tariffId, data }) => {
        const url = tariffId
          ? `/settings/tariffs/${userId}/${type}/${tariffId}`
          : `/settings/tariffs/${userId}/${type}`;

        return { url, method: tariffId ? 'put' : 'post', data };
      },
      onQueryStarted: async ({ callback }, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          if (callback) {
            callback();
          }

          dispatch(addNotification({ type: 'success', text: i18n.t('success') }));
        } catch ({ error }) {
          dispatch(addNotification({ type: 'error', text: error.message }));
        }
      }
    }),

    deleteTariff: builder.mutation({
      invalidatesTags: (_, error) => (error ? [] : ['tariff']),
      query: ({ userId, type, tariffId }) => ({
        url: `/settings/tariffs/${userId}/${type}/${tariffId}`,
        method: 'delete'
      }),
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          dispatch(addNotification({ type: 'success', text: i18n.t('success') }));
        } catch ({ error }) {
          dispatch(addNotification({ type: 'error', text: error.message }));
        }
      }
    })
  })
});

export const {
  useGetTariffsQuery,
  useGetProvidersQuery,
  useLazyStartOauth2ForProvidersQuery,
  useUpdateOrCreateTariffMutation,
  useDeleteTariffMutation
} = tariffsApi;
