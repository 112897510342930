import { createSelector } from '@reduxjs/toolkit';

const getSignInUserMyCompanyVisibleWeb = (state) => state.signIn?.user?.my_company?.visible_web;
const getSignInUserEmailNotification = (state) => state.signIn?.user?.emailnotification;
const getSignInUserGatewayTimeZone = (state) => state.signIn?.user?.gateway?.timeZone;
const getSignInUserOemVisibleWeb = (state) => state.signIn?.user?.oem?.visible_web;
const getSignInUserOemEmployeeVisibleWeb = (state) => state.signIn?.user?.employerOem?.visible_web;
const getSignInUserRoleType = (state) => state.signIn?.user?.role?.type;
const getSignInUserOem_Id = (state) => state.signIn?.user?.oem?._id;
const getSignInUserGateway = (state) => state.signIn?.user?.gateway;
const getSignInUser_Id = (state) => state.signIn?.user?._id;
const getSignInUser = (state) => state.signIn?.user;
const getSignInVerifyingJWT = (state) => state?.signIn?.verifyingJWT;

export const getSignInUserMyCompanyVisibleWebSelector = createSelector([getSignInUserMyCompanyVisibleWeb], (myCompanyVisibleWeb) => myCompanyVisibleWeb);
export const getSignInUserEmailNotificationSelector = createSelector([getSignInUserEmailNotification], (emailnotification) => emailnotification || {});
export const getSignInUserOemVisibleWebSelector = createSelector([getSignInUserOemVisibleWeb, getSignInUserOemEmployeeVisibleWeb], (oemVisibleWeb, oemEmployeeVisibleWeb) => oemVisibleWeb || oemEmployeeVisibleWeb);
export const getSignInUserGatewaySensorsSelector = createSelector([getSignInUserGateway], (gateway) => gateway?.sensors || []);
export const getSignInUserRoleTypeSelector = createSelector([getSignInUserRoleType], (roleType) => roleType || '');
export const getSignInVerifyingJWTSelector = createSelector([getSignInVerifyingJWT], (jwt) => jwt || undefined);
export const getSignInUserOem_IdSelector = createSelector([getSignInUserOem_Id], (_id) => _id);
export const getSignInUser_IdSelector = createSelector([getSignInUser_Id], (_id) => _id);
export const getSignInUserSelector = createSelector([getSignInUser], (user) => user);

export {
  getSignInUserGatewayTimeZone,
  getSignInUserRoleType,
  getSignInUser_Id,
  getSignInUser
};
