import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { replace } from 'react-router-redux';

import { Logo } from '../../components';
import { LanguageSelector } from '../../components/UIKit';
import { getSignInUser } from '../../redux-store/selectors/signIn';
import { logOut } from '../SignIn/slice';
import i18n from '../../i18n';
import { useUpdatePaymentMethodMutation } from '../../services/payments';
import { addNotification } from '../NotificationGenerator/slice';

const PaymentRequired = () => {
  const dispatch = useDispatch();
  const user = useSelector(getSignInUser);
  const userStatus = user?.status;
  const userID = user?._id;

  const [updatePaymentMethod, { isLoading, isFetching, error, data: updatedPaymentMethodData }] = useUpdatePaymentMethodMutation();

  useEffect(() => {
    if (updatedPaymentMethodData) {
      window.location.href = updatedPaymentMethodData.javascriptUrl;
    }
  }, [updatedPaymentMethodData]);

  useEffect(() => {
    if (error) {
      dispatch(addNotification({ type: 'error', text: error.message }));
    }
  }, [dispatch, error]);

  useEffect(() => {
    if (userStatus && userStatus !== 'payment required') {
      dispatch(replace('/'));
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [userStatus]);

  const handleAddPaymentOption = () => {
    updatePaymentMethod({ userID });
  };

  const handleCancel = () => {
    dispatch(logOut());
  };

  return (
    <div className="m-grid m-grid--hor m-grid--root m-page">
      <div
        className="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-1"
        id="m_login"
      >
        <div className="container">
          <div className="m-login__logo row justify-content-center align-items-center">
            <div className="mr-auto col-auto">
              <Logo />
            </div>
            <div className="col-auto">
              <LanguageSelector className="topLanguageSelector" />
            </div>
          </div>
        </div>
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
          className="m-login__wrapper"
        >
          <Box
            sx={{
              textAlign: 'center',
              maxWidth: '860px',
              padding: '72px 80px 42px 80px',
              borderRadius: '24px'
            }}
            className="m-login__container"
          >
            <Typography
              sx={{ fontSize: '24px', fontWeight: 700, marginBottom: '16px' }}
              color="text.primary"
            >
              {i18n.t('paymentOverdueTitle')}
            </Typography>
            <Typography variant="h4" sx={{ marginBottom: '24px' }} color="text.primary">
              {i18n.t('paymentOverdueDesc')}
            </Typography>
            <Box className="m-login__form m-form">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '16px'
                }}
                className="m-login__form-action"
              >
                <button
                  type="button"
                  className="cancel-button"
                  style={{ height: '54px', width: '160px' }}
                  onClick={handleCancel}
                >
                  {i18n.t('cancel')}
                </button>
                <button
                  type="button"
                  className="btn m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn forgot-button m-login__btn--primary"
                  style={{ margin: 0 }}
                  onClick={handleAddPaymentOption}
                  disabled={isLoading || isFetching}
                >
                  {i18n.t('addPaymentOption')}
                </button>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default PaymentRequired;
