import { USERS_PV_INSTALLER_USERS_URL } from '../../api/apiUrls';
import i18n from '../../i18n';

/**
 * Returns list of all statuses
 * @function statusList
 * @memberof module:SettingProfile
 */
export const statusList = () => ([
  {
    value: 'active',
    label: i18n.t('active')
  }, {
    value: 'deactivated',
    label: i18n.t('deactivated')
  }, {
    value: 'pending user confirmation',
    label: i18n.t('pending user confirmation')
  }, {
    value: 'pending admin confirmation',
    label: i18n.t('pending admin confirmation')
  }, {
    value: 'archive',
    label: i18n.t('archive')
  },
  {
    value: 'payment required',
    label: i18n.t('payment required')
  }
]);

export const STATUS_LIST = [
  {
    value: 'active',
    label: i18n.t('active')
  }, {
    value: 'deactivated',
    label: i18n.t('deactivated')
  }, {
    value: 'pending user confirmation',
    label: i18n.t('pending user confirmation')
  }, {
    value: 'pending admin confirmation',
    label: i18n.t('pending admin confirmation')
  }, {
    value: 'archive',
    label: i18n.t('archive')
  },
  {
    value: 'payment required',
    label: i18n.t('payment required')
  }
];

export const defaultOEM = { value: 'null', label: i18n.t('NoOEM') };

export const dataListPviId = 'companies';

export const getPvInstallersConfig = {
  default: {
    url: USERS_PV_INSTALLER_USERS_URL,
    params: {
      _limit: 10000
    }
  }
};

/**
 * changeEmailForm form id
 */
export const CHANGE_EMAIL_FORM = 'changeEmailForm';

/**
 * ChangeSMIdForm form id
 */
export const CHANGE_SMID_FORM = 'changeSMIdForm';

/**
 * settingProfileForm form id
 */
export const SETTING_PROFILE_FORM = 'settingProfileForm';

/**
 * ChangeEmailWindow form id
 */
export const changeEmail = 'changeEmail';

/**
 * ChangePassWindow form id
 */
export const changePassWindowId = 'changePassWindow';

/**
 * ChangeSMIdWindow form id
 */
export const changeSMID = 'changeSMID';
