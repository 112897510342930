import { TARIFFS_CURRENCY_CONFIG_BY_USER_COUNTRY } from './constants';

export const getTaxesAndDutiesPrice = (taxesAndDuties, country) => {
  const { kWh, month } = taxesAndDuties;
  const taxesAndDutiesKWh = TARIFFS_CURRENCY_CONFIG_BY_USER_COUNTRY.taxesAndDutiesKWh[country] || TARIFFS_CURRENCY_CONFIG_BY_USER_COUNTRY.taxesAndDutiesKWh.Default;
  const taxesAndDutiesMonth = TARIFFS_CURRENCY_CONFIG_BY_USER_COUNTRY.taxesAndDutiesMonth[country] || TARIFFS_CURRENCY_CONFIG_BY_USER_COUNTRY.taxesAndDutiesMonth.Default;

  if (kWh && month) {
    return `${kWh} ${taxesAndDutiesKWh} • ${month} ${taxesAndDutiesMonth}`;
  }

  return `${kWh || month} ${kWh ? taxesAndDutiesKWh : taxesAndDutiesMonth}`;
};
