export const SENSORS_LIST_ID = 'sensors';

/**
 * Roles for visible web
 */
export const rolesWithCustomLogo = ['oem', 'pv_installer', 'oem_employee', 'oem_employee_read_only'];
export const EMPLOYEE_ROLE_TYPES = ['pv_installer_employee', 'pv_installer_employee_read_only', 'pv_installer_employee_read_install'];

/**
 * @name houseFuseDefaultValue
 * @constant {Number}
 * @description Return default value If houseFuse equal undefined
 */
export const houseFuseDefaultValue = 0;

/**
 * @name loadManagementValue
 * @constant {Boolean}
 * @description Return default value If loadManagement equal undefined
 */
export const loadManagementValue = false;

export const PROVIDERS_LIST_ID = 'providers';

export const dataExportZohoLink = 'https://solarmanager.zohodesk.com/portal/de/kb/articles/wie-exportiere-ich-meine-daten-als-csv';
export const dataImportZohoLink = 'https://solarmanager.zohodesk.com/portal/de/kb/articles/wie-lade-ich-historische-daten-von-einem-fremdsystem-in-den-solar-manager';
